import React, { Component } from 'react'
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IconLogo from "../components/renderedImages/IconLogo"
import Right from '../components/icons/right'


export default class about extends Component {
    render() {
        return (
            <Layout>
                <SEO title="About" description="Tiger Press is committed to producing quality prints and custom products to help grow your brand." />
                <div className="about-wrap">
                    <h1 className="page-title">About</h1>
                    <h2 className="page-subtitle">"Tiger Press is committed to producing quality prints and custom products to help grow your brand."</h2>

                    <div className="about-img">
                        <IconLogo />
                    </div>

                    <h3>Craig Carroll</h3>
                
                    <main>
                        <p>Tiger Press is an independently owned business assisting other partners with promotional products and other customized requests.</p>
                    </main>

                    <div>
                        <Link to="/products" className="css-link">See Products {Right()}</Link>
                    </div>
                </div>
            </Layout>
        )
    }
}
